import PhotoSwipe from 'photoswipe';
export default {
    init() {
        document.querySelectorAll('.SimpleGallery').forEach( (section) => {
            this.finalize( section );
        });
    },
    finalize( section ) {
        let psObj = null;

        let options = [];
            options.dataSource = [];
            options.fullscreenEl = false;
            options.shareEl = false;

        section.querySelectorAll('.item__button').forEach( ( button, btnIndex) => {
            options.dataSource.push({
                src: button.getAttribute('data-url'),
                w: button.getAttribute('data-width'),
                h: button.getAttribute('data-height'),
            });

            button.addEventListener('click', () => {
                options.index = btnIndex;
                psObj = new PhotoSwipe( options );
                psObj.init();
            });

        });

    }
}