import HeaderSection from 'Sections/HeaderSection/HeaderSection';
import YoutubeSection from 'Sections/YoutubeSection/YoutubeSection';
export default {
	init() {
		if( document.querySelector('main.Homepage') ) {
			HeaderSection.init();
			YoutubeSection.init();
		}
	},
	finalize() {}
}
