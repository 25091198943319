export default {
	init(){

		document.querySelectorAll('section.YoutubeSection').forEach( (node) => {
			this.finalize( node );
		});

	},
	finalize( node ) {
		var placeholder = node.querySelector('.video__placeholder');
		var frame = node.querySelector('iframe');

		placeholder.addEventListener('click', function() {

			frame.setAttribute('src', frame.getAttribute('data-src') );
			placeholder.classList.remove('active');

		});
	}
}