import HeaderSection from 'Sections/HeaderSection/HeaderSection';
import YoutubeSection from 'Sections/YoutubeSection/YoutubeSection';
import OpinionCarousel from 'Sections/OpinionCarousel/OpinionCarousel';
import SimpleGallery from 'Sections/SimpleGallery/SimpleGallery';

export default {
    init() {
        if( document.querySelector('main.HomepageElastic') ) {
            HeaderSection.init();
            YoutubeSection.init();
            SimpleGallery.init();
            OpinionCarousel.init();
        }
    },
    finalize() {}
}   