import $ from 'jquery';
import slick from 'slick-carousel';

export default {
    init() {
        document.querySelectorAll('.OpinionCarousel').forEach(carousel => {
            this.finalize(carousel);
        });
    },
    finalize( node ) {
        let carousel = node.querySelector('.row__wrapper');

        $(carousel).slick({
            arrows: true,
            nextArrow: node.querySelector('.row__button--next'),
            prevArrow: node.querySelector('.row__button--prev'), 
            slidesToShow: 1,
            slidesToScroll: 1,
            rows: false,
            infinite: true,
            autoplay: true,
            autoplaySpeed: 5000,
        });

        carousel.addEventListener('mouseenter', () => {
            $(carousel).slick('slickPause');
        });
        carousel.addEventListener('mouseleave', () => {
            $(carousel).slick('slickPlay');
        });

    }
}