// Defaults
import './style.scss';
import Router from './scripts/Router';
import LazyLoad from './scripts/lazyLoad';
import Subpage from '../src/Layout/Subpage';

// Pages
import homepage from '../src/Pages/Homepage/Homepage';
import homepageelastic from '../src/Pages/HomepageElastic/HomepageElastic';
import singlespecial from '../src/Pages/SingleSpecial/SingleSpecial';
import apartments from '../src/Pages/Apartments/Apartments';
import gallery from '../src/Pages/Gallery/Gallery';
import location from '../src/Pages/Location/Location';


LazyLoad.init();
Subpage.init();

const routes = new Router({
	homepage,
	homepageelastic,
	singlespecial,
	apartments,
	gallery,
	location,
});

document.addEventListener("DOMContentLoaded", function() {
	routes.loadEvents();
});